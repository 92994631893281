import { Ionicons } from "@expo/vector-icons"
import { useTheme, useNavigation } from "@react-navigation/native"
import React from "react"
import { Pressable, useWindowDimensions, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"

interface HeaderProps {
  children: any
}

export default function Header({ children }: HeaderProps) {
  const theme = useTheme()
  const insets = useSafeAreaInsets()
  const dimensions = useWindowDimensions()
  const navigation = useNavigation()
  return (
    <View
      style={{
        paddingHorizontal: dimensions.width > 1000 ? 24 : 12,
        paddingTop: 12 + insets.top,
        paddingBottom: 12,
        minHeight: 53,
        flexDirection: "row",
        alignItems: "center",
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.border,
      }}>
      {dimensions.width > 1000 ? null : (
        <Pressable
          style={{ marginRight: 12 }}
          hitSlop={12}
          onPress={() => {
            // @ts-ignore
            navigation.openDrawer()
          }}>
          <Ionicons name="menu" size={24} color={theme.colors.text} />
        </Pressable>
      )}
      {children}
    </View>
  )
}
