import "expo-firestore-offline-persistence"
import Constants from "expo-constants"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/analytics"
import "firebase/firestore"

const firebaseConfig = {
  apiKey: Constants.manifest.extra.firebaseApiKey,
  authDomain: Constants.manifest.extra.firebaseAuthDomain,
  projectId: Constants.manifest.extra.firebaseProjectId,
  storageBucket: Constants.manifest.extra.firebaseStorageBucket,
  messagingSenderId: Constants.manifest.extra.firebaseMessagingSenderId,
  appId: Constants.manifest.extra.firebaseAppId,
  measurementId: Constants.manifest.extra.firebaseMeasurementId,
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

firebase.firestore().enablePersistence()
