import { Ionicons } from "@expo/vector-icons"
import { useTheme } from "@react-navigation/native"
import moment from "moment"
import pluralize from "pluralize"
import React from "react"
import {
  Modal,
  Pressable,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from "react-native"
import { Task } from "../types"
import firebase from "firebase/app"
import { useToast } from "./Toast"
import Calendar from "./Calendar"
import { useSafeAreaInsets } from "react-native-safe-area-context"

interface SchedulerProps {
  tasks: Task[]
  onRequestClose: () => void
}

export default function Scheduler({ tasks, onRequestClose }: SchedulerProps) {
  const theme = useTheme()
  const insets = useSafeAreaInsets()
  const dimensions = useWindowDimensions()
  const { triggerToast } = useToast()

  async function schedule(day) {
    const batch = firebase.firestore().batch()
    tasks.forEach(task => {
      batch.update(task.ref, {
        day: day,
        updated: moment().toISOString(),
      })
    })
    await batch.commit()
    onRequestClose()
    triggerToast({
      type: "success",
      title:
        "Moved " +
        pluralize("task", tasks.length) +
        " to " +
        (day
          ? moment(day).calendar(null, {
              sameDay: "[today]",
              nextDay: "[tomorrow]",
              nextWeek: "dddd, MMM Do",
              lastDay: "[yesterday]",
              lastWeek: "[last] dddd",
              sameElse: "MMM Do",
            })
          : "someday"),
    })
  }

  return (
    <Modal
      animationType={dimensions.width > 1000 ? "none" : "slide"}
      visible={true}
      transparent={true}
      onRequestClose={onRequestClose}>
      <>
        <Pressable
          style={[
            dimensions.width > 1000 && StyleSheet.absoluteFill,
            {
              flex: 1,
              opacity: 0.7,
              backgroundColor:
                dimensions.width > 1000 ? "#000" : theme.colors.background,
            },
          ]}
          onPress={onRequestClose}
        />
        <View
          style={{
            padding: 16,
            paddingTop: 24,
            margin: dimensions.width > 1000 ? "auto" : 0,
            borderWidth: dimensions.width > 1000 ? 1 : 0,
            borderTopWidth: 1,
            alignItems: "center",
            borderColor: theme.colors.border,
            backgroundColor: theme.colors.background,
          }}>
          <Calendar
            cellSize={42}
            selectedDay={tasks?.[0].day || undefined}
            onSelectDay={day => schedule(moment(day).format("YYYY-MM-DD"))}
          />
          <View
            style={{
              paddingBottom: insets.bottom,
              alignSelf: "stretch",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            <SchedulerItem
              icon="today"
              title="Today"
              onPress={() => {
                schedule(moment().format("YYYY-MM-DD"))
              }}
            />
            <View style={{ width: 4 }} />
            <SchedulerItem
              icon="calendar"
              title={moment().isoWeekday() < 4 ? "Next Monday" : "Monday"}
              onPress={() => {
                schedule(
                  moment().add(1, "week").isoWeekday(1).format("YYYY-MM-DD"),
                )
              }}
            />
            <View style={{ width: 4 }} />
            <SchedulerItem
              icon="rainy"
              title="Someday"
              onPress={() => schedule(null)}
            />
          </View>
        </View>
      </>
    </Modal>
  )
}

interface SchedulerItemProps {
  title: string
  icon: string
  disabled?: boolean
  onPress: () => void
}

function SchedulerItem({ title, icon, disabled, onPress }: SchedulerItemProps) {
  const theme = useTheme()
  return (
    <Pressable
      disabled={disabled}
      onPress={onPress}
      style={{
        flexDirection: "row",
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: theme.colors.border,
      }}>
      {/* @ts-ignore */}
      <Ionicons name={icon} size={16} color={theme.colors.text} />
      <Text
        style={{
          marginLeft: 4,
          fontWeight: "700",
          color: theme.colors.text,
        }}>
        {title}
      </Text>
    </Pressable>
  )
}
