import AsyncStorage from "@react-native-async-storage/async-storage"
import { DarkTheme, DefaultTheme, Theme } from "@react-navigation/native"
import React, { createContext, useContext, useEffect, useState } from "react"
import { useColorScheme } from "react-native"

const LightTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: "rgb(255,255,255)",
    card: "rgb(250,250,250)",
  },
}

const themes: Theme[] = [DarkTheme, LightTheme]

export interface ThemeContext {
  theme: Theme
  themes: Theme[]
  saveTheme: (theme: Theme) => Promise<void>
}

export const ThemeContext = createContext({} as ThemeContext)

export function useTheme() {
  return useContext(ThemeContext)
}

interface ThemeProviderProps {
  children: any
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  const colorScheme = useColorScheme()
  const [theme, setTheme] = useState<Theme>(DarkTheme)

  useEffect(() => {
    loadTheme()
  }, [])

  async function loadTheme() {
    const preferredTheme = await AsyncStorage.getItem("PreferredTheme")
    if (preferredTheme) {
      setTheme(JSON.parse(preferredTheme) as Theme)
    } else if (colorScheme === "dark") {
      setTheme(DarkTheme)
    } else {
      setTheme(LightTheme)
    }
  }

  async function saveTheme(theme: Theme) {
    setTheme(theme)
    await AsyncStorage.setItem("PreferredTheme", JSON.stringify(theme))
  }

  return (
    <ThemeContext.Provider value={{ theme, themes, saveTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
