import React from "react"
import {
  Platform,
  Pressable,
  PressableProps,
  StyleProp,
  View,
  ViewStyle,
} from "react-native"
import { useTheme } from "./Theme"

interface CheckboxProps extends PressableProps {
  checked?: boolean
  style?: StyleProp<ViewStyle>
}

export default function Checkbox({ checked, style, ...rest }: CheckboxProps) {
  const { theme } = useTheme()
  return (
    <Pressable
      {...rest}
      style={[
        style,
        // @ts-ignore
        Platform.OS === "web" && { outlineStyle: "none" },
      ]}>
      {({ pressed, focused }) => (
        <View
          style={{
            width: 24,
            height: 24,
            borderRadius: 24,
            borderWidth: 1,
            borderColor: focused
              ? theme.colors.primary
              : checked
              ? theme.colors.primary
              : theme.colors.border,
            backgroundColor: checked
              ? theme.colors.primary
              : pressed
              ? theme.colors.card
              : undefined,
            alignItems: "center",
            justifyContent: "center",
          }}>
          {checked ? (
            <View
              style={{
                height: 12,
                width: 6,
                borderRightWidth: 2,
                borderBottomWidth: 2,
                borderColor: theme.colors.background,
                transform: [{ translateY: -1 }, { rotate: "45deg" }],
              }}
            />
          ) : null}
        </View>
      )}
    </Pressable>
  )
}
