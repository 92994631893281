import { Ionicons } from "@expo/vector-icons"
import { useTheme } from "@react-navigation/native"
import firebase from "firebase/app"
import moment from "moment"
import React, { forwardRef, useRef, useState } from "react"
import {
  Platform,
  Pressable,
  TextInput,
  useWindowDimensions,
  View,
  Text,
} from "react-native"
import { Task } from "../types"
import Checkbox from "./Checkbox"
import Scheduler from "./Scheduler"
import TextEditor, { TextEditorHandle } from "./TextEditor"
import { useToast } from "./Toast"

interface TasksProps {
  tasks: Task[]
  day: string | null
}

export default function TaskList({ tasks, day }: TasksProps) {
  const theme = useTheme()
  const inputsRef = useRef<TextEditorHandle[]>([])
  const [autoFocus, setAutoFocus] = useState(false)

  return (
    <View>
      {tasks
        .slice()
        .sort((a, b) => (a.created > b.created ? 1 : -1))
        .map((task, index) => (
          <TaskItem
            key={task.id}
            ref={ref => (inputsRef.current[index] = ref)}
            task={task}
            day={day}
            onFocusLast={() => {
              inputsRef.current[tasks.length]?.focus()
            }}
            onFocusPrev={() => {
              inputsRef.current[Math.max(0, index - 1)]?.focus()
            }}
            onFocusNext={() => {
              inputsRef.current[index + 1]?.focus()
            }}
          />
        ))}
      <View
        style={{
          flexDirection: "row",
          paddingVertical: 12,
          marginBottom: 48,
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.border,
        }}>
        <Checkbox disabled={true} style={{ marginRight: 8 }} />
        <TextEditor
          ref={ref => (inputsRef.current[tasks.length] = ref)}
          key={"new" + tasks.length}
          placeholder="Add a task"
          defaultValue=""
          blurOnSubmit={true}
          autoFocus={autoFocus}
          onBlur={async e => {
            e.preventDefault()
            const text = e.nativeEvent.text.trim()
            if (text) {
              await firebase
                .firestore()
                .collection("users")
                .doc(firebase.auth().currentUser.uid)
                .collection("tasks")
                .add({
                  day: day,
                  text: text,
                  created: moment().toISOString(),
                  updated: moment().toISOString(),
                  completed: null,
                })
              setAutoFocus(false)
            }
          }}
          onSubmitEditing={() => setAutoFocus(true)}
          onKeyPress={async e => {
            const { key, text } = e.nativeEvent
            if (key === "ArrowUp") {
              inputsRef.current[tasks?.length - 1]?.focus()
            }
            if (key === "Backspace" && text.length === 0) {
              inputsRef.current[tasks?.length - 1]?.focus()
            }
          }}
        />
      </View>
    </View>
  )
}

interface TaskProps {
  task: Task
  day: string
  onFocusPrev: () => void
  onFocusNext: () => void
  onFocusLast: () => void
}

const TaskItem = forwardRef<TextInput, TaskProps>((props, ref) => {
  const { task, day, onFocusPrev, onFocusNext, onFocusLast } = props
  const dimensions = useWindowDimensions()
  const theme = useTheme()
  const { triggerToast } = useToast()
  const [deleting, setDeleting] = useState(false)
  const [scheduling, setScheduling] = useState(false)
  const [hovering, setHovering] = useState(false)

  return (
    <View
      style={{
        position: "relative",
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.border,
      }}>
      <View
        style={{ flexDirection: "row", paddingTop: 12 }}
        // @ts-ignore
        onMouseOver={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}>
        <Checkbox
          style={{ marginRight: 8 }}
          checked={!!task.completed}
          onPress={() => {
            task.ref.update({
              day: day,
              completed: task.completed ? null : moment().toISOString(),
            })
          }}
        />
        <TextEditor
          ref={ref}
          defaultValue={task.text}
          blurOnSubmit={true}
          style={{
            opacity: task.completed ? 0.3 : 1,
            textDecorationLine: task.completed ? "line-through" : "none",
          }}
          onBlur={e => {
            const text = e.nativeEvent.text.trim()
            if (text !== task.text && !deleting) {
              task.ref.update({
                text: text,
                updated: moment().toISOString(),
              })
            }
          }}
          onSubmitEditing={e => {
            const text = e.nativeEvent.text.trim()
            if (text !== task.text) {
              task.ref.update({
                text: text,
                updated: moment().toISOString(),
              })
            }
            onFocusLast()
          }}
          onKeyPress={e => {
            const { key, text } = e.nativeEvent
            if (key === "Backspace" && text.length === 0) {
              setDeleting(true)
              task.ref.delete()
              onFocusPrev()
            }
            if (key === "ArrowUp") {
              onFocusPrev()
            }
            if (key === "ArrowDown") {
              onFocusNext()
            }
          }}
        />
        <Pressable
          // @ts-ignore
          style={({ focused }) => ({
            alignItems: "center",
            justifyContent: "center",
            opacity: hovering || focused || dimensions.width <= 1000 ? 1 : 0,
            width: 24,
            height: 24,
            borderWidth: 1,
            borderRadius: 4,
            borderColor: theme.colors.border,
          })}
          hitSlop={16}
          onPress={() => setScheduling(true)}>
          {/* @ts-ignore */}
          {({ focused, pressed }) => (
            <Ionicons
              name="calendar"
              size={14}
              color={
                focused || pressed ? theme.colors.primary : theme.colors.text
              }
            />
          )}
        </Pressable>
        {scheduling ? (
          <Scheduler
            tasks={[task]}
            onRequestClose={() => {
              setScheduling(false)
              setHovering(false)
            }}
          />
        ) : null}
      </View>
      <View style={{ height: 12, paddingBottom: 2 }}>
        <Text
          style={{
            marginTop: -2,
            // marginLeft: 32,
            alignSelf: "flex-end",
            fontSize: 10,
            color: theme.colors.notification,
          }}>
          {task.day === day ? null : moment(task.day).fromNow()}
        </Text>
      </View>
    </View>
  )
})
