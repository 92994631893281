import React from "react"
import firebase from "firebase/app"
import { Platform, Pressable, Text, View } from "react-native"
import { useToast } from "./Toast"
import { Ionicons } from "@expo/vector-icons"
import { useTheme } from "@react-navigation/native"
import * as Crypto from "expo-crypto"
import * as AppleAuthentication from "expo-apple-authentication"

export default function LoginWithApple() {
  const { triggerToast } = useToast()
  const theme = useTheme()

  if (Platform.OS !== "ios") {
    // Can't get this working for BOTH ios and web at the same time because
    // of single services ID field in firebase auth configuration.
    return null
  }

  return (
    <Pressable
      style={{
        marginVertical: 12,
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 4,
        width: 240,
        height: 48,
        paddingHorizontal: 24,
        backgroundColor: theme.dark ? "white" : "black",
      }}
      onPress={async () => {
        try {
          if (Platform.OS === "ios") {
            const csrf = Math.random().toString(36).substring(2, 15)
            const nonce = Math.random().toString(36).substring(2, 10)
            const hashedNonce = await Crypto.digestStringAsync(
              Crypto.CryptoDigestAlgorithm.SHA256,
              nonce,
            )
            const appleCredential = await AppleAuthentication.signInAsync({
              requestedScopes: [
                AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                AppleAuthentication.AppleAuthenticationScope.EMAIL,
              ],
              state: csrf,
              nonce: hashedNonce,
            })
            if (appleCredential.identityToken) {
              const provider = new firebase.auth.OAuthProvider("apple.com")
              const credential = provider.credential({
                idToken: appleCredential.identityToken,
                rawNonce: nonce,
              })
              await firebase.auth().signInWithCredential(credential)
            }
          }

          if (Platform.OS === "web") {
            const provider = new firebase.auth.OAuthProvider("apple.com")
            await firebase.auth().signInWithPopup(provider)
          }
        } catch (error) {
          triggerToast({
            type: "error",
            title: error.message,
          })
        }
      }}>
      <Ionicons
        name="logo-apple"
        size={24}
        color={theme.dark ? "black" : "white"}
      />
      <View style={{ flex: 1, alignItems: "center" }}>
        <Text
          style={{
            fontSize: 16,
            fontWeight: "600",
            color: theme.dark ? "black" : "white",
          }}>
          Sign in with Apple
        </Text>
      </View>
    </Pressable>
  )
}
