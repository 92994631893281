import * as React from "react"
import * as WebBrowser from "expo-web-browser"
import { ResponseType } from "expo-auth-session"
import * as Google from "expo-auth-session/providers/google"
import firebase from "firebase/app"
import { Image, Pressable, Text, View } from "react-native"
import Constants from "expo-constants"
import { useTheme } from "@react-navigation/native"
import { useToast } from "./Toast"

WebBrowser.maybeCompleteAuthSession()

export default function LoginWithGoogle() {
  const theme = useTheme()
  const { triggerToast } = useToast()

  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    clientId: Constants.manifest.extra.googleWebClientId,
    iosClientId: Constants.manifest.extra.googleIosClientId,
  })

  React.useEffect(() => {
    if (response?.type === "success") {
      const { id_token } = response.params
      const credential = firebase.auth.GoogleAuthProvider.credential(id_token)
      firebase.auth().signInWithCredential(credential)
    } else if (response?.type === "error") {
      triggerToast({
        type: "error",
        title: response.error?.message,
      })
    }
  }, [response])

  return (
    <Pressable
      style={{
        marginVertical: 12,
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 4,
        width: 240,
        height: 48,
        paddingHorizontal: 24,
        borderWidth: 1,
        borderColor: theme.colors.border,
        backgroundColor: "white",
      }}
      onPress={() => promptAsync()}>
      <Image
        source={require("../../assets/google-icon.png")}
        style={{
          width: 24,
          height: 24,
        }}
      />
      <View style={{ flex: 1, alignItems: "center" }}>
        <Text
          style={{
            fontSize: 16,
            fontFamily: "RobotoMedium",
            color: "#666",
          }}>
          Sign in with Google
        </Text>
      </View>
    </Pressable>
  )
}
