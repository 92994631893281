import { useTheme } from "@react-navigation/native"
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { Animated, Modal, Pressable, Text, View } from "react-native"

type ToastPayload = {
  title: string
  type?: "info" | "success" | "error"
  time?: number
}

interface ToastContext {
  triggerToast: (toast: ToastPayload) => void
}

export const ToastContext = createContext({} as ToastContext)

export function useToast() {
  return useContext(ToastContext)
}

interface ToastProviderProps {
  children: any
}

export function ToastProvider({ children }: ToastProviderProps) {
  const theme = useTheme()
  const [toast, setToast] = useState(null)
  const transition = useRef(new Animated.Value(0)).current

  useEffect(() => {
    if (toast) {
      let timeout = setTimeout(() => {
        Animated.spring(transition, {
          toValue: 0,
          useNativeDriver: true,
        }).start(({ finished }) => {
          if (finished) {
            setToast(null)
          }
        })
      }, toast.time)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [toast])

  function triggerToast({ title, type, time }: ToastPayload) {
    transition.setValue(0)
    setToast({
      title: title,
      type: type || "info",
      time: time || 2000,
    })
    Animated.spring(transition, {
      toValue: 1,
      useNativeDriver: true,
    }).start()
  }

  return (
    <ToastContext.Provider value={{ triggerToast }}>
      <View style={{ flex: 1, overflow: "hidden" }}>
        {children}
        <Animated.View
          pointerEvents="none"
          style={{
            position: "absolute",
            right: 24,
            bottom: 24,
            padding: 24,
            borderRadius: 4,
            maxWidth: 300,
            borderWidth: toast?.type === "info" ? 1 : 0,
            borderColor: theme.colors.border,
            backgroundColor: theme.colors.text,
            opacity: transition,
            transform: [
              {
                translateX: transition.interpolate({
                  inputRange: [0, 1],
                  outputRange: [100, 0],
                }),
              },
            ],
          }}>
          <Text style={{ color: theme.colors.background }}>{toast?.title}</Text>
        </Animated.View>
      </View>
    </ToastContext.Provider>
  )
}
