import firebase from "firebase/app"
import moment from "moment"
import React from "react"
import { useWindowDimensions, View } from "react-native"
import { Note } from "../types"
import TextEditor from "./TextEditor"

interface NotesProps {
  note: Note | null
  day: string
}

export default function Notes({ note, day }: NotesProps) {
  const dimensions = useWindowDimensions()
  return (
    <View style={{ paddingVertical: 8 }}>
      <TextEditor
        key={note?.id}
        defaultValue={note?.text || ""}
        placeholder="Add a note"
        multiline={true}
        style={{ minHeight: dimensions.height - 100 }}
        onBlur={e => {
          const value = e.nativeEvent.text
          if (note) {
            note.ref.update({
              text: value,
              updated: moment().toISOString(),
            })
          } else {
            firebase
              .firestore()
              .collection("users")
              .doc(firebase.auth().currentUser.uid)
              .collection("notes")
              .doc(day)
              .set({
                day: day,
                text: value,
                created: moment().toISOString(),
                updated: moment().toISOString(),
              })
          }
        }}
      />
    </View>
  )
}
