import { Ionicons } from "@expo/vector-icons"
import { useTheme } from "@react-navigation/native"
import * as React from "react"
import { Text, View } from "react-native"
import LoginWithApple from "../components/LoginWithApple"
import LoginWithGoogle from "../components/LoginWithGoogle"
import Constants from "expo-constants"

export default function LoginScreen() {
  const theme = useTheme()
  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.colors.background,
      }}>
      <Ionicons name="today" size={64} color={theme.colors.text} />
      <Text
        style={{
          fontSize: 32,
          fontWeight: "700",
          color: theme.colors.text,
          marginBottom: 32,
        }}>
        DailyTodo
      </Text>
      <LoginWithGoogle />
      <LoginWithApple />
      <Text
        style={{
          fontSize: 10,
          color: theme.colors.text,
        }}>
        {"\nApp Version: "}
        {Constants.nativeAppVersion}
        {"\nBuild Version: "}
        {Constants.nativeBuildVersion}
        {"\nRelease Channel: "}
        {Constants.manifest.releaseChannel}
        {"\nRelease Id: "}
        {Constants.manifest.releaseId}
        {"\nRevision Id: "}
        {Constants.manifest.revisionId}
      </Text>
      <View style={{ height: 32 }} />
    </View>
  )
}
