import React from "react"
import {
  Pressable,
  Text,
  View,
  ScrollView,
  useWindowDimensions,
} from "react-native"
import Checkbox from "../components/Checkbox"
import Header from "../components/Header"
import { ThemeContext, useTheme } from "../components/Theme"

export default function HelpScreen() {
  const { theme } = useTheme()
  const dimensions = useWindowDimensions()
  return (
    <View style={{ flex: 1 }}>
      <Header>
        <Text
          style={{ fontSize: 20, fontWeight: "700", color: theme.colors.text }}>
          Help
        </Text>
      </Header>
      <View
        style={{
          flex: 1,
          paddingVertical: 24,
          paddingHorizontal: dimensions.width > 1000 ? 24 : 12,
        }}>
        <Text style={{ fontSize: 16, color: theme.colors.text }}>
          Please contact robert@nicer.io.
        </Text>
      </View>
    </View>
  )
}
