import { Ionicons } from "@expo/vector-icons"
import { useTheme } from "@react-navigation/native"
import firebase from "firebase/app"
import moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore"
import {
  KeyboardAvoidingView,
  Pressable,
  ScrollView,
  Text,
  useWindowDimensions,
  View,
} from "react-native"
import Header from "../components/Header"
import Notes from "../components/Notes"
import TaskList from "../components/TaskList"
import { Note, Task } from "../types"

export default function DailyScreen() {
  const dimensions = useWindowDimensions()
  const theme = useTheme()

  const [offset, setOffset] = useState(0)

  const day = useMemo(() => {
    return moment().add(offset, "days").format("YYYY-MM-DD")
  }, [offset])

  const isToday = moment().isSame(day, "day")

  const [tasks = [], tasksLoading] = useCollectionData<Task>(
    firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .collection("tasks")
      .orderBy("created")
      .where("day", "==", day),
    {
      idField: "id",
      refField: "ref",
    },
  )

  const [overdue = [], overdueLoading] = useCollectionData<Task>(
    isToday
      ? firebase
          .firestore()
          .collection("users")
          .doc(firebase.auth().currentUser.uid)
          .collection("tasks")
          .where("completed", "==", null)
          .where("day", "!=", null)
          .where("day", "<", day)
          .orderBy("day")
          .orderBy("created")
      : null,
    {
      idField: "id",
      refField: "ref",
    },
  )

  const [note] = useDocumentData<Note>(
    firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .collection("notes")
      .doc(day),
    {
      idField: "id",
      refField: "ref",
    },
  )

  let items = []
  if (!tasksLoading && !overdueLoading) {
    items = [...overdue, ...tasks]
  }

  return (
    <KeyboardAvoidingView behavior="padding" style={{ flex: 1 }}>
      <Header>
        <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
          <Pressable
            onPress={() => setOffset(0)}
            style={{
              paddingVertical: 4,
              paddingHorizontal: 12,
              borderWidth: 1,
              borderRadius: 4,
              borderColor: theme.colors.border,
            }}>
            <Text
              style={{
                fontWeight: "700",
                color: theme.colors.text,
              }}>
              Today
            </Text>
          </Pressable>
          <View style={{ width: 12 }} />
          <Pressable onPress={() => setOffset(x => x - 1)}>
            <Ionicons name="arrow-back" size={24} color={theme.colors.text} />
          </Pressable>
          <Pressable onPress={() => setOffset(x => x + 1)}>
            <Ionicons
              name="arrow-forward"
              size={24}
              color={theme.colors.text}
            />
          </Pressable>
          <View style={{ width: 12 }} />
          <Text
            style={{
              fontSize: 20,
              fontWeight: "700",
              color: theme.colors.text,
            }}>
            {moment(day).format("dddd, MMMM D")}
          </Text>
        </View>
      </Header>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          flex: dimensions.width > 1000 ? 1 : undefined,
          flexDirection: dimensions.width > 1000 ? "row" : "column",
        }}>
        <View
          style={{
            flex: dimensions.width > 1000 ? 1 : undefined,
            padding: dimensions.width > 1000 ? 24 : 12,
          }}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "700",
              color: theme.colors.text,
            }}>
            Tasks
          </Text>
          <TaskList tasks={items} day={day} />
        </View>
        <View
          style={{
            flex: dimensions.width > 1000 ? 1 : undefined,
            padding: dimensions.width > 1000 ? 24 : 12,
            borderLeftWidth: dimensions.width > 1000 ? 1 : 0,
            borderLeftColor:
              dimensions.width > 1000 ? theme.colors.border : undefined,
          }}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "700",
              color: theme.colors.text,
            }}>
            Journal
          </Text>
          <Notes note={note} day={day} />
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  )
}
