import { Ionicons } from "@expo/vector-icons"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { createDrawerNavigator } from "@react-navigation/drawer"
import { NavigationContainer } from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { registerRootComponent } from "expo"
import AppLoading from "expo-app-loading"
import { Asset } from "expo-asset"
import * as Font from "expo-font"
import firebase from "firebase/app"
import React, { useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { Text, useWindowDimensions } from "react-native"
import { SafeAreaProvider } from "react-native-safe-area-context"
import Drawer from "./components/Drawer"
import { ThemeProvider, useTheme } from "./components/Theme"
import { ToastProvider } from "./components/Toast"
import DailyScreen from "./screens/Daily"
import HelpScreen from "./screens/Help"
import LoginScreen from "./screens/Login"
import SettingsScreen from "./screens/Settings"
import SomedayScreen from "./screens/Someday"
import WeeklyScreen from "./screens/Weekly"
import "./services/firebase"
import * as serviceWorkerRegistration from "./services/serviceWorkerRegistration"

const linking = {
  prefixes: ["https://dailytodo.app", "dailytodo://"],
  config: {
    screens: {
      Login: "login",
      Daily: "daily",
      Weekly: "weekly",
      Someday: "someday",
      Settings: "settings",
      Help: "help",
    },
  },
}

const Root = createDrawerNavigator()

const Auth = createNativeStackNavigator()

function Router() {
  const dimensions = useWindowDimensions()
  const { theme } = useTheme()
  const [user] = useAuthState(firebase.auth())

  return (
    <NavigationContainer
      theme={theme}
      linking={linking}
      fallback={<Text>Loading...</Text>}>
      <ToastProvider>
        {user ? (
          <Root.Navigator
            drawerContent={props => <Drawer {...props} />}
            screenOptions={{
              headerShown: false,
              drawerType: dimensions.width > 1000 ? "permanent" : "slide",
              drawerStyle: {
                width: 200,
                height: dimensions.height,
              },
            }}>
            <Root.Screen name="Daily" component={DailyScreen} />
            <Root.Screen name="Weekly" component={WeeklyScreen} />
            <Root.Screen name="Someday" component={SomedayScreen} />
            <Root.Screen name="Settings" component={SettingsScreen} />
            <Root.Screen name="Help" component={HelpScreen} />
          </Root.Navigator>
        ) : (
          <Auth.Navigator
            screenOptions={{
              headerShown: false,
            }}>
            <Auth.Screen name="Login" component={LoginScreen} />
          </Auth.Navigator>
        )}
      </ToastProvider>
    </NavigationContainer>
  )
}

function App() {
  const [ready, setReady] = useState(false)

  const [_, authenticating] = useAuthState(firebase.auth())

  if (!ready || authenticating) {
    return (
      <AppLoading
        startAsync={async () => {
          await Asset.loadAsync(require("../assets/google-icon.png"))
          await Font.loadAsync({
            RobotoMedium: require("../assets/Roboto-Medium.ttf"),
          })
          await Font.loadAsync(Ionicons.font)
          await AsyncStorage.getItem("PreferredTheme")
        }}
        onFinish={() => setReady(true)}
        onError={console.warn}
      />
    )
  }

  return (
    <SafeAreaProvider>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </SafeAreaProvider>
  )
}

export default registerRootComponent(App)

serviceWorkerRegistration.register()
