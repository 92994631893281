import { useTheme } from "@react-navigation/native"
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react"
import {
  NativeSyntheticEvent,
  Platform,
  TextInput,
  TextInputKeyPressEventData,
  TextInputProps,
  View,
  Text,
  StyleSheet,
} from "react-native"

interface TextEditorKeyPressEventData extends TextInputKeyPressEventData {
  text: string
}

interface TextEditorProps extends TextInputProps {
  onKeyPress?: (e: NativeSyntheticEvent<TextEditorKeyPressEventData>) => void
}

export interface TextEditorHandle {
  clear: () => void
  focus: () => void
}

export default forwardRef<TextEditorHandle, TextEditorProps>(
  ({ style, defaultValue, onKeyPress, ...rest }, ref) => {
    const theme = useTheme()

    const [value, setValue] = useState(defaultValue || "")
    const [height, setHeight] = useState<number>(18)

    const inputRef = useRef<TextInput>(null)
    useImperativeHandle(ref, () => ({
      clear: () => {
        inputRef.current?.clear()
        setValue("")
      },
      focus: () => {
        inputRef.current?.focus()
      },
    }))

    return (
      <View
        style={{
          flex: 1,
          position: "relative",
          minHeight: height,
        }}>
        <View pointerEvents="none">
          <Text
            style={[
              styles.editor,
              {
                color: theme.colors.text,
              },
              style,
            ]}>
            {value.split(" ").map((word, index) => {
              const isMention = /^@[A-Za-z0-9]/.test(word)
              const isProject = /^\+[A-Za-z]/.test(word)
              if (isMention || isProject) {
                return (
                  <Text key={index} style={{ color: theme.colors.primary }}>
                    {word}{" "}
                  </Text>
                )
              }
              return word + " "
            })}
          </Text>
        </View>
        <TextInput
          ref={inputRef}
          value={value}
          autoCorrect={false}
          onChangeText={setValue}
          scrollEnabled={false}
          multiline={true}
          selectionColor={theme.colors.primary}
          placeholderTextColor={theme.colors.border}
          {...rest}
          style={[
            styles.editor,
            {
              color: value ? "transparent" : theme.colors.text,
              borderWidth: 0,
              paddingTop: 0,
            },
            Platform.OS === "web" && {
              // @ts-ignore
              caretColor: theme.colors.text,
              outlineStyle: "none",
            },
            style,
          ]}
          onLayout={e => {
            if (Platform.OS === "web") {
              // @ts-ignore
              const element = e.nativeEvent.target as unknown as HTMLTextAreaElement // prettier-ignore
              element.style.height = "0px"
              element.style.height = element.scrollHeight + "px"
            }
          }}
          onChange={e => {
            if (Platform.OS === "web") {
              const element = e.target as unknown as HTMLTextAreaElement
              element.style.height = "0px"
              element.style.height = element.scrollHeight + "px"
            }
          }}
          onContentSizeChange={e => {
            setHeight(e.nativeEvent.contentSize.height)
          }}
          onKeyPress={e => {
            if (onKeyPress) {
              const modifiedEvent = {
                ...e,
                nativeEvent: {
                  key: e.nativeEvent.key,
                  text: value,
                },
              }
              onKeyPress(modifiedEvent)
            }
          }}
        />
      </View>
    )
  },
)

const styles = StyleSheet.create({
  editor: {
    position: "absolute",
    top: 0,
    width: "100%",
    fontSize: 16,
    lineHeight: 24,
  },
})
