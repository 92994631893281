import { useTheme } from "@react-navigation/native"
import firebase from "firebase/app"
import React from "react"
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore"
import {
  KeyboardAvoidingView,
  ScrollView,
  Text,
  useWindowDimensions,
  View,
} from "react-native"
import Header from "../components/Header"
import Notes from "../components/Notes"
import TaskList from "../components/TaskList"
import { Note, Task } from "../types"

export default function SomedayScreen() {
  const dimensions = useWindowDimensions()
  const theme = useTheme()

  const [tasks] = useCollectionData<Task>(
    firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .collection("tasks")
      .orderBy("created")
      .where("day", "==", null),
    {
      idField: "id",
      refField: "ref",
    },
  )

  const [note] = useDocumentData<Note>(
    firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .collection("notes")
      .doc("someday"),
    {
      idField: "id",
      refField: "ref",
    },
  )

  return (
    <KeyboardAvoidingView behavior="padding" style={{ flex: 1 }}>
      <Header>
        <Text
          style={{
            fontSize: 20,
            color: theme.colors.text,
            fontWeight: "700",
          }}>
          Someday
        </Text>
      </Header>
      <ScrollView
        style={{ flex: 1 }}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flex: dimensions.width > 1000 ? 1 : undefined,
          flexDirection: dimensions.width > 1000 ? "row" : "column",
        }}>
        <View
          style={{
            minHeight: 400,
            flex: dimensions.width > 1000 ? 1 : undefined,
            padding: dimensions.width > 1000 ? 24 : 12,
          }}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "700",
              color: theme.colors.text,
            }}>
            Tasks
          </Text>
          <TaskList tasks={tasks || []} day={null} />
        </View>
        <View
          style={{
            flex: dimensions.width > 1000 ? 1 : undefined,
            padding: dimensions.width > 1000 ? 24 : 12,
            borderLeftWidth: dimensions.width > 1000 ? 1 : 0,
            borderLeftColor:
              dimensions.width > 1000 ? theme.colors.border : undefined,
          }}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "700",
              color: theme.colors.text,
            }}>
            Journal
          </Text>
          <Notes note={note} day={"someday"} />
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  )
}
