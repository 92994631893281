import { Ionicons } from "@expo/vector-icons"
import { useFocusEffect, useTheme } from "@react-navigation/native"
import firebase from "firebase/app"
import moment from "moment"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import {
  FlatList,
  Pressable,
  ScrollView,
  Text,
  useWindowDimensions,
  View,
} from "react-native"
import Header from "../components/Header"
import TaskList from "../components/TaskList"
import { Task } from "../types"

export default function WeeklyScreen() {
  const theme = useTheme()
  const dimensions = useWindowDimensions()
  const colWidth = Math.min(400, dimensions.width)

  const [offset, setOffset] = useState(0)

  const weekdays = useMemo(() => {
    return [1, 2, 3, 4, 5, 6, 7].map(i => {
      return moment().add(offset, "week").isoWeekday(i).format("YYYY-MM-DD")
    })
  }, [offset])

  const [tasks] = useCollectionData<Task>(
    firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .collection("tasks")
      .orderBy("day")
      .where("day", ">=", weekdays[0])
      .where("day", "<=", weekdays[6]),
    {
      idField: "id",
      refField: "ref",
    },
  )

  const weekTitle = useMemo(() => {
    let startDay = moment().add(offset, "weeks").startOf("week")
    let endDay = moment().add(offset, "weeks").endOf("week")
    if (startDay.isSame(endDay, "month")) {
      return startDay.format("MMMM D") + "-" + endDay.format("D, YYYY")
    } else {
      return startDay.format("MMMM D") + "-" + endDay.format("MMMM D, YYYY")
    }
  }, [offset])

  const scrollToColumn = useCallback(() => {
    scrollRef.current?.scrollToOffset({
      animated: false,
      offset: offset ? 0 : Math.max(0, (moment().isoWeekday() - 2) * colWidth),
    })
  }, [offset])

  const scrollRef = useRef<FlatList>(null)

  useEffect(() => {
    scrollToColumn()
  }, [offset])

  useFocusEffect(scrollToColumn)

  return (
    <View style={{ flex: 1 }}>
      <Header>
        <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
          <Pressable
            onPress={() => setOffset(0)}
            style={{
              paddingVertical: 4,
              paddingHorizontal: 12,
              borderWidth: 1,
              borderRadius: 4,
              borderColor: theme.colors.border,
            }}>
            <Text
              style={{
                fontWeight: "700",
                color: theme.colors.text,
              }}>
              This Week
            </Text>
          </Pressable>
          <View style={{ width: 12 }} />
          <Pressable onPress={() => setOffset(x => x - 1)}>
            <Ionicons name="arrow-back" size={24} color={theme.colors.text} />
          </Pressable>
          <Pressable onPress={() => setOffset(x => x + 1)}>
            <Ionicons
              name="arrow-forward"
              size={24}
              color={theme.colors.text}
            />
          </Pressable>
          <View style={{ width: 12 }} />
          <Text
            style={{
              fontSize: 20,
              fontWeight: "700",
              color: theme.colors.text,
            }}>
            {weekTitle}
          </Text>
        </View>
      </Header>
      <FlatList
        ref={scrollRef}
        style={{ flex: 1 }}
        horizontal={true}
        data={weekdays}
        keyExtractor={day => day}
        renderItem={({ item: day, index }) => {
          return (
            <View style={{ width: colWidth }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  paddingVertical: 12,
                  paddingHorizontal: 24,
                  borderBottomWidth: 1,
                  borderBottomColor: theme.colors.border,
                }}>
                <View
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: 4,
                    marginRight: 8,
                    alignItems: "center",
                    justifyContent: "center",
                    borderWidth: 1,
                    borderColor: moment().isSame(day, "day")
                      ? theme.colors.text
                      : theme.colors.border,
                    backgroundColor: moment().isSame(day, "day")
                      ? theme.colors.text
                      : undefined,
                  }}>
                  <Text
                    style={{
                      fontSize: 6,
                      lineHeight: 8,
                      fontWeight: "700",
                      textTransform: "uppercase",
                      color: moment().isSame(day, "day")
                        ? theme.colors.background
                        : theme.colors.text,
                    }}>
                    {moment(day).format("MMM")}
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      lineHeight: 12,
                      fontWeight: "700",
                      color: moment().isSame(day, "day")
                        ? theme.colors.background
                        : theme.colors.text,
                    }}>
                    {moment(day).format("D")}
                  </Text>
                </View>
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "700",
                    color: theme.colors.text,
                  }}>
                  {moment(day).format("dddd")}
                </Text>
              </View>
              <ScrollView
                key={day}
                style={{
                  borderLeftWidth: index ? 1 : 0,
                  borderLeftColor: theme.colors.border,
                }}
                contentContainerStyle={{
                  paddingVertical: 12,
                  paddingHorizontal: 24,
                }}>
                <TaskList
                  tasks={(tasks || []).filter(task => task.day === day)}
                  day={day}
                />
              </ScrollView>
            </View>
          )
        }}
      />
    </View>
  )
}
