import { Ionicons } from "@expo/vector-icons"
import { useTheme } from "@react-navigation/native"
import moment, { Moment } from "moment"
import React, { useMemo, useState } from "react"
import { Pressable, Text, View } from "react-native"

interface CalendarProps {
  selectedDay?: string | undefined
  onSelectDay: (day: string) => void
  cellSize?: number
}

export default function Calendar({
  cellSize = 40,
  selectedDay,
  onSelectDay,
}: CalendarProps) {
  const theme = useTheme()

  const [year] = useState<number>(moment(selectedDay).year())
  const [month, setMonth] = useState<number>(moment(selectedDay).month())

  const cells: Moment[] = useMemo(() => {
    let cells = []
    // Start on the Monday of the week that has the first day of the month
    let startDay = moment().year(year).month(month).startOf("month").startOf("week") // prettier-ignore
    // Add days to cells until we have 6 weeks worth (enough for any month)
    for (let i = 0; i < 42; i++) {
      cells.push(moment(startDay).add(i, "days"))
    }
    return cells
  }, [month, year])

  return (
    <View style={{ width: cellSize * 7 }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}>
        <Text
          style={{
            color: theme.colors.text,
            fontWeight: "700",
            fontSize: 20,
          }}>
          {moment().year(year).month(month).format("MMMM YYYY")}
        </Text>
        <View style={{ flex: 1 }} />
        <Pressable onPress={() => setMonth(x => x - 1)}>
          <Ionicons name="arrow-back" size={24} color={theme.colors.text} />
        </Pressable>
        <Pressable onPress={() => setMonth(x => x + 1)}>
          <Ionicons name="arrow-forward" size={24} color={theme.colors.text} />
        </Pressable>
      </View>
      <View
        style={{
          marginTop: 24,
          marginBottom: 4,
          flexDirection: "row",
        }}>
        {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
          <View key={index} style={{ flex: 1 }}>
            <Text
              style={{
                fontSize: 12,
                fontWeight: "700",
                textAlign: "center",
                color: theme.colors.text,
              }}>
              {day}
            </Text>
          </View>
        ))}
      </View>
      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        {cells.map(day => {
          const isToday = moment().isSame(day, "day")
          const isSelected = moment(selectedDay).isSame(day, "day")
          const isInMonth = moment()
            .year(year)
            .month(month)
            .isSame(day, "month")
          return (
            <Pressable
              key={day.toISOString()}
              disabled={!isInMonth}
              hitSlop={Math.max(0, (44 - cellSize) / 2)} // touch target should be at least 44
              onPress={() => onSelectDay(day.format("YYYY-MM-DD"))}>
              {/* @ts-ignore */}
              {({ hovered }) => (
                <View
                  style={{
                    width: cellSize,
                    height: cellSize,
                    borderRadius: cellSize,
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: isInMonth ? 1 : 0,
                    backgroundColor: isSelected
                      ? theme.colors.primary
                      : hovered
                      ? theme.colors.card
                      : undefined,
                  }}>
                  <Text
                    style={{
                      fontWeight: "700",
                      textAlign: "center",
                      color: isSelected
                        ? theme.colors.background
                        : isToday
                        ? theme.colors.primary
                        : theme.colors.text,
                    }}>
                    {day.format("D")}
                  </Text>
                </View>
              )}
            </Pressable>
          )
        })}
      </View>
    </View>
  )
}
