import React from "react"
import {
  Pressable,
  Text,
  View,
  ScrollView,
  useWindowDimensions,
} from "react-native"
import Checkbox from "../components/Checkbox"
import Header from "../components/Header"
import { ThemeContext, useTheme } from "../components/Theme"

export default function SettingsScreen() {
  const { theme, themes, saveTheme } = useTheme()
  const dimensions = useWindowDimensions()
  return (
    <View style={{ flex: 1 }}>
      <Header>
        <Text
          style={{ fontSize: 20, fontWeight: "700", color: theme.colors.text }}>
          Settings
        </Text>
      </Header>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingVertical: 24,
          paddingHorizontal: dimensions.width > 1000 ? 24 : 12,
          maxWidth: 600,
        }}>
        {themes.map((theme, index) => (
          <ThemeContext.Provider key={index} value={{ theme } as ThemeContext}>
            <Pressable
              style={{
                overflow: "hidden",
                position: "relative",
                marginBottom: 8,
                borderWidth: 1,
                borderColor: theme.colors.border,
                backgroundColor: theme.colors.background,
                borderRadius: 4,
              }}
              onPress={() => saveTheme(theme)}>
              <View
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: theme.colors.card,
                  transform: [{ skewX: "-15deg" }, { translateX: 220 }],
                }}
              />
              <View style={{ padding: 24 }}>
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "700",
                    color: theme.colors.text,
                    marginBottom: 8,
                  }}>
                  Theme
                </Text>
                <View style={{ flex: 1, flexDirection: "row" }}>
                  <Checkbox
                    checked={true}
                    style={{ marginTop: -2, marginRight: 8 }}
                  />
                  <Text
                    style={{ flex: 1, fontSize: 16, color: theme.colors.text }}>
                    Lorem ipsum dolor sit amet consectetur,{" "}
                    <Text style={{ color: theme.colors.notification }}>
                      adipisicing elit
                    </Text>
                    .
                  </Text>
                </View>
              </View>
            </Pressable>
          </ThemeContext.Provider>
        ))}
      </ScrollView>
    </View>
  )
}
