import { Ionicons } from "@expo/vector-icons"
import { Link, useTheme } from "@react-navigation/native"
import firebase from "firebase/app"

import React from "react"
import { Pressable, Text, useWindowDimensions, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"

interface NavItemProps {
  size: number
  icon: string
  title: string
  to?: string
  onPress?: () => void
}

function NavItem({ icon, title, to, onPress, size }: NavItemProps) {
  const theme = useTheme()
  const Element = to ? Link : Pressable

  return (
    <Element
      to={to}
      onPress={onPress}
      style={{
        marginVertical: size / 2,
      }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}>
        <Ionicons name={icon} size={size * 1.25} color={theme.colors.text} />
        <Text
          style={{
            fontSize: size,
            fontWeight: "700",
            marginLeft: 8,
            color: theme.colors.text,
          }}>
          {title}
        </Text>
      </View>
    </Element>
  )
}

export default function Drawer({ navigation }) {
  const insets = useSafeAreaInsets()
  const dimensions = useWindowDimensions()
  return (
    <View
      style={{
        flex: 1,
        paddingTop: 24 + insets.top,
        paddingHorizontal: 24,
        paddingBottom: 32 + insets.bottom,
      }}>
      <View style={{ height: 64 }} />
      <NavItem icon="today" title="Daily" to="/daily" size={20} />
      {dimensions.width > 1000 ? (
        <NavItem icon="calendar" title="Weekly" to="/weekly" size={20} />
      ) : null}
      <NavItem icon="bulb" title="Someday" to="/someday" size={20} />
      <View style={{ flex: 1 }} />
      <NavItem icon="help-buoy" title="Help" to="/help" size={18} />
      <NavItem icon="settings" title="Settings" to="/settings" size={18} />
      <NavItem
        icon="log-out"
        title="Logout"
        onPress={() => firebase.auth().signOut()}
        size={18}
      />
    </View>
  )
}
